class CubeMapsData {
  static list = [
    {
      title: 'Лобби',
      name: 'lobby',
      points: [],
      initRotation: 0
    },
    {
      name: '3k_room1',
      title: '3к квартира 1',
      points: [],
      initialPointId: 'CubeMap_20',
      initRotation: Math.PI
    },
    {
      name: '3k_room2',
      title: '3к квартира 2',
      points: [],
      initRotation: Math.PI
    },
    {
      name: '2k_room1',
      title: '2к квартира 1',
      points: [],
      initRotation: -Math.PI / 2.3
    },
    {
      name: '2k_room2',
      title: '2к квартира 2',
      points: [],
      initRotation: 0
    },
    {
      name: '2k_room3',
      title: '2к квартира 3',
      points: [],
      initRotation: 0
    },
    {
      name: '1k_room1',
      title: '1к квартира 1',
      points: [],
      initRotation: Math.PI / 2,
      initialPointId: 'CubeMap_01'
    },
    {
      name: '1k_room2',
      title: '1к квартира 2',
      points: [],
      initRotation: 0,
      initialPointId: 'CubeMap_01'
    },
    {
      name: 'studio1',
      title: 'Студия 1',
      points: [],
      initRotation: 0
    },
    {
      name: 'studio2',
      title: 'Студия 2',
      points: [],
      initRotation: 0
    },
  ]

  static GetData(name) {
    return this.list.filter(item => item.name === name)[0]
  }

  /**
   *
   * @return {{name: string, initRotation: number, title: string, points: []}|{name: string, initRotation: number, title: string, points: []}|{initialPointId: string, name: string, initRotation: number, title: string, points: []}|{name: string, initRotation: number, title: string, points: []}|{name: string, initRotation: number, title: string, points: []}|{name: string, initRotation: number, title: string, points: []}}
   */
  static get first() {
    return this.list[0]
  }
}

export default CubeMapsData
