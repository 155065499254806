<template lang="pug">
  .color-select
    .color-item(v-for="(item, index) in toursList",
      :key="index",
      :class="item.color",
      @click="onItemClick(item)")
</template>
<script>
export default {
  name: 'ColorSelect',
  props: {
    selectedTour : {
      type: String
    }
  },

  data () {
    return {
      toursList: [
        {
          tour: '2k_room2',
          color: 'white'
        },
        {
          tour: '2k_room3',
          color: 'grey'
        }
      ]
    }
  },

  computed: {

  },

  methods: {
    onItemClick(item) {
      this.$router.push(`/${item.tour}`)
    }
  }
}
</script>
