<template lang="pug">
  .tour-application
    canvas(ref="canvas")
    // .loading-indicator(:class="{visible: loading && !firstTime}")
    tour-name
    navigation-bar(v-model="cubeMapData", v-if="cubeMapData.navigation || cubeMapData.locationPoints", @selected="onNavigationSelected", :activeCubeId="activeCubeId")
    preloader(:visible="firstTime && loading", :tourName="tourName")
    .created-by
      a(href="https://immercyb.com/", target="_blank") Сделано в Immersive Cybernetics
    // attention-popup
    color-select(v-if="colorSelectVisible", :selectedTour="tourName")
</template>
<script>
import TourSceneController from "@/components/MeshedTour/classes/TourSceneController.class";
import CubeMapsData from "@/classes/CubeMapsData.class";
import TourName from "@/components/MeshedTour/TourName";
import NavigationBar from "@/components/MeshedTour/NavigationBar";
import Preloader from "@/components/MeshedTour/Preloader";
import AttentionPopup from "./MeshedTour/AttentionPopup";
import ColorSelect from "./MeshedTour/ColorSelect";

export default {
  name: 'MeshedTour',
  components: {ColorSelect, AttentionPopup, Preloader, NavigationBar, TourName},
  data () {
    return {
      activeCubeId: null,
      sceneController: null,
      cubeMapsData: null,
      loading: true,
      firstTime: true,
    }
  },

  mounted() {
    this.createSceneController()
  },

  destroyed() {
    this.destroySceneController()
  },

  watch: {
    tourName() {
      this.destroySceneController()

      this.firstTime = true
      this.loading = true

      this.$nextTick(() => {
        this.createSceneController()
      })
    }
  },

  computed: {
    tourName() {
      return this.$route.params.tourName ? this.$route.params.tourName : CubeMapsData.first.name
    },

    cubeMapData() {
      return CubeMapsData.GetData(this.tourName)
    },

    colorSelectVisible() {
      return ['2k_room2', '2k_room3'].indexOf(this.tourName) >= 0
    }
  },

  methods: {
    createSceneController() {
      const data = CubeMapsData.GetData(this.tourName)

      this.sceneController = new TourSceneController({
        canvas: this.$refs.canvas,
        settings: {
          cubeSize: 3.6,
          cameraAcceleration: 0.001
        }
      })

      this.sceneController.addEventListener(TourSceneController.Events.loadStarted, this.onLoadStarted)
      this.sceneController.addEventListener(TourSceneController.Events.loadComplete, this.onLoadComplete)
      this.sceneController.addEventListener(TourSceneController.Events.locationTagClicked, this.onLocationTagClicked)

      this.sceneController.createTour({
        modelName: data.name,
        cameraRotation: data.initRotation,
        startFromCubeWithId: data.initialPointId ? data.initialPointId : null,
        locationTags: this.cubeMapData.locationPoints
      })
    },

    onNavigationSelected(data) {
      this.firstTime = true
      this.sceneController.gotoCubeWithId(data.id, data.rotation)
    },

    onLoadStarted() {
      this.loading = true
    },

    onLoadComplete() {
      this.activeCubeId = this.sceneController.activeCube.id
      this.loading = false
      this.firstTime = false
    },

    onLocationTagClicked(event) {
      const locationTag = event.detail
      window.location = `/${locationTag.data.locationName}`
    },

    destroySceneController() {
      this.sceneController.removeEventListener(TourSceneController.Events.loadStarted, this.onLoadStarted)
      this.sceneController.removeEventListener(TourSceneController.Events.loadComplete, this.onLoadComplete)
      this.sceneController.dispose()
      this.sceneController = null
    }
  }
}
</script>
