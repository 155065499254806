<template lang="pug">
  .test
    canvas(ref="canvas")
    .temp(ref="temp")
</template>
<script>
import CubeMerger from "@/classes/merger/CubeMerger.class";
import CubeData from "@/classes/merger/CubeData.class";
import {Vector3} from "@babylonjs/core";
import {Vector} from "@flatten-js/core";
import * as BABYLON from "@babylonjs/core";

export default {
  name: 'CubeMergerScene',
  data() {
    return {
      time: 0,
      cubes: []
    }
  },

  mounted() {
    this.initBabylon()
  },

  destroyed() {
    this._engine.dispose()
  },

  methods: {

    initBabylon() {
      this._engine = new BABYLON.Engine(this.$refs.canvas, true)
      this._engine.setHardwareScalingLevel(1 / window.devicePixelRatio)
      this._scene = new BABYLON.Scene(this._engine)

      this._engine.runRenderLoop(() => {
        this.time += 0.01
        this.cubes.forEach((cube, index) => {
          if (index > 0) {
            // const sin = Math.abs(Math.sin(this.time) * 2) + 3
            // const direction = Vector3.Zero().subtract(cube.position).normalize()
            // cube.position = direction.multiplyByFloats(sin, sin, sin)
            // cube.rotation.y = sin
          }
        })
        this._scene.render()
      })

      this._createCamera()
      this.testCalculation()
    },

    _createCamera() {

      const camera = new BABYLON.ArcRotateCamera("UniversalCamera", 0, Math.PI / 2, 7, Vector3.Zero(), this._scene)
      camera.fov = 1.2
      camera.angularSensibility = 1000
      camera.touchAngularSensibility = 600
      camera.invertRotation = true
      camera.attachControl(this._canvas, true)
      camera.minZ = 0

      const boxMesh = BABYLON.MeshBuilder.CreateBox('box', {
        size: 0.1
      }, this._scene)

      boxMesh.position.z = 2.5

      boxMesh.material = new BABYLON.StandardMaterial('box', this._scene)
      boxMesh.material.emissiveColor = BABYLON.Color3.Red()

      const light = new BABYLON.HemisphericLight('light', Vector3.Up(), this._scene)
      console.log(light)

      this._camera = camera
    },

    createCube(cubeData) {
      const shapes = cubeData.getShapes()
      const meshes = []
      const cubeMesh = new BABYLON.Mesh('cube')

      shapes.forEach(shapeItem => {
        const pointsContainer = shapeItem.getShape()

        pointsContainer.forEach(shapePoints => {
          if (shapePoints.length > 0) {
            const mesh = new BABYLON.Mesh()
            const corners = shapePoints.map(item => new BABYLON.Vector2(item.x, item.y))
            // console.log(corners)
            const builderFront = new BABYLON.PolygonMeshBuilder(shapeItem.orientation, corners, this._scene)
            const builderBack = new BABYLON.PolygonMeshBuilder(shapeItem.orientation, corners, this._scene)
            const meshFront = builderFront.build()
            const meshBack = builderBack.build()
            meshBack.flipFaces()

            meshFront.parent = mesh
            meshBack.parent = mesh

            if (shapeItem.orientation === 'pz') {
              mesh.rotateAround(Vector3.Zero(), Vector3.Right(), -Math.PI / 2)
              mesh.position.z = cubeData.size / 2
            } else if (shapeItem.orientation === 'nz') {
              mesh.rotateAround(Vector3.Zero(), Vector3.Right(), -Math.PI / 2)
              mesh.position.z = -cubeData.size / 2
            } else if (shapeItem.orientation === 'nx') {
              mesh.rotateAround(Vector3.Zero(), Vector3.Forward(), -Math.PI / 2)
              mesh.position.x = -cubeData.size / 2
              mesh.rotateAround(Vector3.Zero(), Vector3.Right(), -Math.PI / 2)
            } else if (shapeItem.orientation === 'px') {
              mesh.rotateAround(Vector3.Zero(), Vector3.Forward(), -Math.PI / 2)
              mesh.position.x = cubeData.size / 2
              mesh.rotateAround(Vector3.Zero(), Vector3.Right(), -Math.PI / 2)
            } else if (shapeItem.orientation === 'py') {
              // mesh.rotateAround(Vector3.Zero(), Vector3.Forward(), -Math.PI / 2)
              mesh.position.y = cubeData.size / 2
            } else if (shapeItem.orientation === 'ny') {
              // mesh.rotateAround(Vector3.Zero(), Vector3.Forward(), -Math.PI / 2)
              mesh.position.y = -cubeData.size / 2
            }

            // meshFront.parent = null
            // meshBack.parent = null

            // mesh.dispose()

            meshes.push(meshFront)
            meshes.push(meshBack)
          }
        })
      })

      setTimeout( () => {
        const resultMesh = BABYLON.Mesh.MergeMeshes(meshes, true)
        resultMesh.parent = cubeMesh

        // resultMesh.material = new BABYLON.StandardMaterial('cube', this._scene)
        // resultMesh.material.diffuseColor = BABYLON.Color3.White()
        // resultMesh.material.alpha = 0.5
        // resultMesh.material.wireframe = false

        const skyboxMaterial = new BABYLON.StandardMaterial("skyBox", this._scene);
        skyboxMaterial.backFaceCulling = false
        skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("/test-cube-maps/2/", this._scene)
        skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE
        skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0)
        skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0)
        resultMesh.material = skyboxMaterial;


        cubeMesh.position = cubeData.position
        this.cubes.push(cubeMesh)
      }, 0)
    },

    testCalculation() {
      const cubesData = [
        new CubeData({position: new Vector3(0.4, 0, 0.3), size: 4}),
        new CubeData({position: new Vector3(1,0, 1.99999999999999999999), size: 4}),
        new CubeData({position: new Vector3(0,0, 1.99999999999999999999), size: 4}),
        new CubeData({position: new Vector3(1.5,0, 1.59999999999999999999), size: 4}),
        // new CubeData({position: new Vector3(3,1,3), size: 4}),
        // new CubeData({position: new Vector3(4,0,4), size: 4}),
      ]

      for (let i = 0; i < 100 ; i++) {
        cubesData.push(new CubeData({position: new Vector3(Math.random() * 10, Math.random() * 10, Math.random() * 10), size: 4}),)
      }

      const merger = new CubeMerger({
        cubesData
      })

      merger.createCubes()

      let html = ''
      const list = [merger._cubesData[1]]

      merger._cubesData.forEach(cubeData => {
        this.createCube(cubeData)
      })
      // this.createCube(merger._cubesData[1])

      list.forEach((cube) => {
        html += '<div class="faces-list">'
        cube.faces.forEach((faceItem) => {
          const polygon = faceItem._polygon.translate(new Vector(50, 50))
          html += '<div class="face"><div class="or">'+faceItem.orientation+'</div><svg id="mySVG" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">'
          html += polygon.svg()
          html += '</svg></div>'
        })
        html += '</div>'
      })

      this.$refs.temp.innerHTML = html
    }

  }
}
</script>
<style>
.test{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.test canvas {
  width: 100%;
  height: 100%;
}
.temp {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
}

.faces-list {
  display: flex;
  flex-direction: row;
}

.face {
  border: 1px solid #fff;
  background-color: #ff0000;
  width: 100px;
  height: 100px;
  position: relative;
}
.face .or {
  position: absolute;
  top: 20px;
  left: 20px;
  display: block;
}
</style>
