import Vue from 'vue'
import VueRouter from 'vue-router'
import Tests from "@/components/Tests";
import CubeMergerScene from "@/components/CubeMergerScene";
import MeshedTour from "@/components/MeshedTour";

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'test',
    component: Tests
  },
  {
    path: '/cube-merger',
    name: 'CubeMerger',
    component: CubeMergerScene
  },
  {
    path: '/meshed-tour',
    name: 'MeshedTour',
    component: MeshedTour
  },
  {
    path: '/',
    name: 'Home',
    component: MeshedTour
  },
  {
    path: '/:tourName',
    name: 'Home',
    component: MeshedTour
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
