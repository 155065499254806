<template lang="pug">
  .tour-name(:class="{visible: this.visible}")
    .text AFI Park
</template>
<script>
export default {
  name: 'TourName',
  props: ['visible']
}
</script>
